@import "../../../assets/scss/base";

.folders-sidebar {
    width: 15%;
}

.private-shared-folders-container {
    max-height: 35rem;
}

.private-shared-folders-container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
    margin-top: 1px;
}

.private-shared-folders-container::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.private-shared-folders-container::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}